import { Button, Carousel, Col, Container, Form, Image, ListGroup, ListGroupItem, Row } from 'react-bootstrap'
import NavBar from '../Components/NavBar';
import ContactForm from '../Components/ContactForm';

const LandingPage = () => {
    return (
        <>
            
            <Container>
                <Carousel>
                    <Carousel.Item>
                        <Image src="lp_26.png" className='carrousel-image'/>
                        <Carousel.Caption>
                            <h4>Compartilhe seus melhores momentos na Europa junto com seu Pet!</h4>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="lp_25.png" className='carrousel-image'/>
                        <Carousel.Caption>
                            <h4>Baixe já o Guia de Viajem Pet EuroPatinhas!</h4>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="lp_24.png" className='carrousel-image'/>
                        <Carousel.Caption>
                            <h4>Conte conosco nesta Jornada! Solicite contato Agora!</h4>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <div className='site-divider'>

                </div>
                <ListGroup>
                    <ListGroupItem>
                        <div className='container-list-group-items'>
                            <Row className='line-download-guide'>
                                <Col md={12} lg={6}>
                                    <div className='line-download-guide-text-parent'>
                                        <h2 className='justified-text'><b>Leve seu melhor amigo para a Europa com o suporte do Guia de Viagem Pet EuroPatinhas!</b></h2>
                                        <div>
                                            <p className='justified-text'>
                                            Vai se mudar ou viajar para a Europa e quer que seu pet esteja ao seu lado?
                                            </p>
                                            <p className='justified-text'>
                                            Na EuroPatinhas, entendemos que seu animal de estimação é parte da família e
                                            merece uma viagem segura e sem complicações.
                                            </p>
                                            <p className='justified-text'>
                                            Sabemos que o processo para levar seu pet para outro país pode parecer desafiador,
                                            repleto de burocracias e etapas difíceis, por isso elaboramos um guia prático para
                                            que você entenda os passos para realizar esta jornada!
                                            </p>
                                        </div>
                                        <Button href='./guia_viagem_pet.pdf' download className='download-button'>Baixe grátis agora</Button>
                                    </div>
                                </Col>
                                <Col className='preview-image-container' md={12} lg={6}>
                                    <Image src='preview_ebook.png' thumbnail />
                                </Col>
                            </Row>
                        </div>
                    </ListGroupItem>
                    <div className='site-divider'>

                    </div>
                    <ListGroupItem>
                        <div className='container-list-group-items'>
                            <h3><b>Conte conosco nesta Jornada!</b></h3>                            
                            <p>Com nossa assessoria especializada, você evita atrasos e imprevistos que poderiam comprometer a viagem do seu pet.
                                Nossa missão é fazer com que cada etapa dessa jornada seja tranquila e organizada.
                                Com nossa experiente consultoria ajudamos você a levar de forma simples e confiável para os Países membros da União Européia:
                            </p>
                            <p><i>
                                Alemanha, Áustria, Bélgica, Bulgária, Chéquia, Chipre, Croácia, Dinamarca, Eslováquia,
                                Eslovénia, Espanha, Estónia, Finlândia, França, Grécia, Hungria, Irlanda, Itália, Letónia,
                                Lituânia, Luxemburgo, Malta, Países Baixos, Polónia, Portugal, Roménia e Suécia.
                                </i></p>
                            <p><b><i>
                                Entre em contato agora!
                                </i></b></p>
                            <ContactForm></ContactForm>
                        </div>
                    </ListGroupItem>
                    <div className='site-divider'>

                    </div>
                </ListGroup>
                <div className="footer">
                    <Row>
                        <Col md={4} lg={4}>
                            <a href='mailto:contato.europatinhas@gmail.com'>contato.europatinhas@gmail.com</a>
                        </Col>
                        <Col md={4} lg={4}>
                            <a href='tel:+351920406230'>+351 920 406 230</a>
                        </Col>
                        <Col md={4} lg={4}>
                            <a href='https://www.instagram.com/europatinhas/'>@EuroPatinhas</a>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};

export default LandingPage;